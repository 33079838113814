export const PortalApiUrls = Object.freeze({
  GET_USER_PROFILE: "/user/customer/profile/",
  UPDATE_USER_PROFILE: "/user/customer/profile/",
  GET_ORGANIZATION_TYPE: "/company/choices/organization_type/",
  CREATE_COMPANY: "/company/customer/",
  UPDATE_COMPANY: "/company/customer/",
  GET_COMPANY_PROFILE: "/company/customer/",
  GET_CATEGORIES: "/category/",
  SEARCH_CATEGORIES: "/category/search/",
  UPDATE_COMPANY_CATEGORIES: "/company/customer/category/",
  UPDATE_BID_CATEGORIES: "/bid/customer/category/",
  GET_STATES: "/global-places/states/",
  GET_CITIES: "/global-places/cities/",
  RETRIEVE_CITY: "/global-places/cities/",
  CREATE_ADDRESS: "/company/customer/address/",
  DELETE_ADDRESS: "/company/customer/address/",
  GET_CERTIFICATE_TYPE: "/company/choices/certificate_type/",
  CREATE_CERTIFICATE: "/company/customer/certificate/",
  DELETE_CERTIFICATE: "/company/customer/certificate/",
  CHANGE_PASSWORD: "/user/change_password/",
  GET_BID_TYPE: "/bid/choices/",
  CREATE_BID: "/bid/customer/",
  CLONE_BID: "bid/customer/clone/",
  BID_ADD_PRODUCT: "bid/customer/product/",
  BID_PRODUCTS_LIST: "bid/customer/product/",
  BID_EDIT_PRODUCT: "bid/customer/product/",
  BID_DELETE_PRODUCT: "bid/customer/product/",
  CREATED_LIST_BIDS: "/bid/customer/",
  INVITED_BID_LIST: "/bid/customer/invited/",
  RELATED_BID_LIST: "/bid/customer/related/",
  ADD_QUESTION: "/bid/customer/question/",
  DELETE_QUESTION: "/bid/customer/question/",
  UPLOAD_DOCUMENT: "/bid/customer/document/",
  DELETE_DOCUMENT: "/bid/customer/document/",
  RETRIEVE_CREATED_BID: "/bid/customer/",
  RETRIEVE_INVITED_BID: "/bid/customer/invited/",
  UPDATE_BID: "/bid/customer/",
  CREATE_AMENDMENT: "/bid/customer/amendment/",
  CREATE_ORDER: "/payment/customer/order/",
  VERIFY_PAYMENT: "/payment/customer/verify/",
  CANCEL_BID: "/bid/customer/cancel/",
  SEARCH_BIDS: "/bid/customer/search/",
  SEND_INVITE: "/bid/customer/invite/",
  INVITE_ACTION: "/bid/customer/invited/action/",
  UPDATE_ANSWER: "/bid/customer/answer/",
  PARTICIPANTS_LIST: "/bid/customer/participant/",
  REVOKE_PARTICIPANT: "/bid/customer/revoke/",
  COMPANY_LIST: "/company/customer/list/",
  BID_SAMPLE_ACTION: "/bid/customer/sample/action/",
  SEND_RELATED_REQUEST: "/bid/customer/related/request/",
  RETRIEVE_COMPANY_DETAILS: "/company/customer/",
  BID_INVITED_REQUESTS: "/bid/customer/related/requested/",
  REMARK: "/bid/customer/remark/",
  PREVIOUS_BID: "/bid/customer/previous/",
  DELETE_BID_REQUEST: "/bid/customer/related/request/",
});
